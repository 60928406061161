<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <div v-if="event">
        <v-toolbar v-if="eventUtil.newLayout" elevation="0" color="grey lighten-5" class="split-toolbar">
          <v-toolbar-title class="ml-3 py-2 subtitle">
            {{ event.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div class="actions">
            
          </div>
        </v-toolbar>

        <v-card-text>
          <p class="d-flex align-center" style="flex-direction: column;">
            <v-dialog v-model="showPostDialog" max-width="600px">
              <template v-slot:activator="{ on }">
                <v-btn large color="primary" class="" v-on="on"><v-icon small class="mr-2">fa fa-plus</v-icon> Create New Post</v-btn>
              </template>
              <v-card>
                <v-card-title>Post to Event Feed</v-card-title>
                <v-card-text class="mt-4 pb-0">

                  <v-form lazy-validation ref="postForm">
                    <v-textarea
                      v-model="message"
                      :rules="messageRules"
                      label="Message"
                      />

                    <v-switch 
                      v-model="sendPush" 
                      label="Send Push Notification to the app"
                      />

                    <v-btn large color="primary" @click="postToFeed" :loading="$store.getters.isLoading">Post to Feed</v-btn>
                  </v-form>
                  <br/><br/>
                </v-card-text>
              </v-card>
            </v-dialog>            
          </p>
        </v-card-text>
                
        <v-card-text>
          <FeedList :feed-items="feedItems" />
        </v-card-text>
      </div>
    </v-card>
  </div>      
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import eventService from "@/services/eventService";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import FeedList from '@/components/FeedList.vue';
import EventUtil from "@/util/eventUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    FeedList,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      org: null,
      event: null,
      feedItems: null,
      showPostDialog: false,
      message: null,
      sendPush: false,
      messageRules: [
        v => !!v || "Please enter the message you want to post",
      ],
    };
  },
  async mounted() {
    await this.getProfile();

    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
    
  },
  methods: {
    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;

      await this.loadFeed();
    },

    async loadFeed() {
      this.feedItems = (await eventService.getFeed(this.event.id)).data.data;
    },

    async getProfile() {
      if (this.user) {
        this.org = (await profileService.getGroup(this.$route.params.id)).data;
        await this.getEvent(this.$route.params.eventId);
      }
    },


    async postToFeed() {
      this.$refs.postForm.validate();
      if (this.message) {
        const model = {
          msg: this.message,
          img: null,
          video: null,
          link: null,
        };
        const response = (await eventManagerService.postToFeed(this.event.id, model, this.sendPush, /*selectedRaceId:*/null)).data;
        this.$helpers.toastResponse(this, response, '👍 Message posted to feed');
        this.showPostDialog = false;
        this.message = null;
        this.sendPush = false;
        await this.loadFeed();
      }
    },
  },
  computed: {
    breadcrumbItems() {
      return !this.org || !this.event ? [] : [
        { text: this.org.name, exact: true, to: { name: 'organization', params: {id: this.org.id} } },
        { text: 'My Challenges', exact: true, to: { name: 'organizationChallenges', params: {id: this.org.id }} },
        { text: this.event.name, exact: true, to: { name: 'organizationChallengesView', params: {id: this.org.id, eventId: this.event.id}} },
        { text: 'Feed', disabled: true },
      ];
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.getEvent(to.params.id);
    next();
  },
};
</script>
<style lang="scss">
  .size--xs .split-toolbar  {
    height: auto !important;
    .v-toolbar__content {
      height: auto !important;
      flex-direction: column;
      align-items: start;
      div.actions {
        align-self: flex-end;
      }
    }
  }
  .full-dialog { 
      width:100vw; 
      height: 100vh;
      /*.toastui-editor-defaultUI { position: absolute; top:0; bottom: 0; right: 0; left: 0;}*/
    }
</style>

